html {
	scroll-behavior: auto;
}

body,
html {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	color: #050c52;
	font-family: 'Comodo Stamp', sans-serif;
}

@font-face {
	font-family: 'Comodo Stamp';
	font-style: normal;
	font-weight: normal;
	src: local('Comodo Stamp'), url('assets/Comodo-Stamp.ttf') format('truetype'),
		url('assets/Comodo-Stamp.otf') format('opentype');
}

@font-face {
	font-family: 'Darumadrop One';
	font-style: normal;
	font-weight: normal;
	src: local('Darumadrop One'), url('assets/DarumadropOne-Regular.ttf') format('truetype');
}

body.no-scroll {
	overflow: hidden;
}

.section {
	min-height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: scroll;
	animation: none;
}

.main-page {
	background:
		url('assets/landing-main.svg') no-repeat center center,
		url('assets/bg.jpg') center center;
	background-size: cover, contain;
	background-attachment: scroll;
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: none;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    text-align: center;
    padding: 2rem;
    box-sizing: border-box;
  }

  .separator {
    background-image: url('assets/heart.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 3rem;
    height: 3rem;
  }

.header-text {
    margin-top: auto;
    font-size: clamp(1.6rem, 5vw, 3rem);
}

.header-text .line1 {
	white-space: nowrap;
	font-size: clamp(1rem, 5vw, 3rem);
	margin: 0 auto;
	width: fit-content;
	text-align: center;
}

.header-text .line1,
.header-text .line2 {
	font-size: clamp(1.6rem, 5vw, 3rem);
	font-weight: normal;
}

.names {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: clamp(1rem, 5vw, 2rem);
    font-size: clamp(1.2rem, 5vw, 2.5rem);
    margin: auto 0;
  }

.section-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.date {
    display: flex;
    flex-direction: column;
	font-family: 'Darumadrop One', sans-serif;
    align-items: center;
    font-size: clamp(1rem, 4vw, 1.5rem);
    line-height: 1.5;
  }

.color-maroon {
	color:#700712;
}

  .date-line {
    margin-bottom: 0.5rem;
    text-align: center;
  }

.event-section {
	background-image: url('assets/scrap.jpg');
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: auto;
	min-height: 100vh;
	padding: 20px;
	box-sizing: border-box;
	overflow: hidden;
}

.engagement-section {
	background-image: url('assets/bg.jpg');
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;
	width: 100%;
	min-height: 100vh;
}

.engagement-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: 1200px;
	gap: 20px;
	box-sizing: border-box;
}

.iframe-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.title {
	font-size: clamp(1rem, 3vw, 2rem);
	text-align: center;
}

.subtitle {
	font-size: clamp(1rem, 3vw, 2rem);
	text-align: center;
}

.engagement-text,
.engagement-image {
	flex: 1;
	max-width: 50%;
}

.engagement-image {
	display: flex;
	justify-content: center;
	align-items: center;
}

.engagement-image img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
	border-radius: 10px;
}

.countdown-section {
	background-image: url('assets/scrap.jpg');
	min-height: unset !important;
	height: 20vh;
	padding: 0;
}

.footer {
	background:
		url('assets/second-page.svg') no-repeat bottom center,
		url('assets/bg.jpg') no-repeat center center;
	background-size: 100%, cover;
	background-attachment: scroll;
	height: 60vh;
	min-height: unset !important;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: none;
}

@keyframes fadeIn {
	from {
	  opacity: 0;
	  transform: translateY(20px); 
	}
	to {
	  opacity: 1;
	  transform: translateY(0);
	}
  }

  .scroll-triggered {
	opacity: 0;
	transform: translateY(20px);
	transition: opacity 0.8s ease-out, transform 0.8s ease-out;
	will-change: opacity, transform; 
  }

  .scroll-triggered.visible {
	opacity: 1;
	transform: translateY(0);
  }

@media (max-width: 1024px) {

	.main-page {
		background-size: contain;
		overflow: hidden;
		padding: 0 10px;
		box-sizing: border-box;
	}

	.engagement-container {
		flex-direction: column;
		align-items: center;
	}

	.engagement-text,
	.engagement-image {
		max-width: 100%;
		flex: none;
	}

	.footer {
		background:
			url('assets/second-page.svg') no-repeat bottom center,
			url('assets/bg.jpg') no-repeat center center;
		background-size: contain, cover;
		height: 30vh;
		background-position: bottom center, center center;
	}

	.content {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		padding: unset;
		height: 70%;
	}

	.header-text {
		margin: 0;
		padding: 0;
		margin-bottom: 2vh;
		text-align: center;
	}

	.line1 {
		white-space: normal;
		font-size: clamp(1.2rem, 4vw, 2.5rem);
		margin-bottom: 5vh;
	}

	.names {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 2rem;
		font-size: clamp(1rem, 4vw, 2rem);
	}

	.separator {
		width: 3rem;
		height: 3rem;
	}

	.date {
		font-size: clamp(1rem, 4vw, 1.8rem);
		text-align: center;
		margin-top: 2vh;
	}
}

@media (max-width: 600px) {
	.main-page {
		background-size: contain;
		overflow: hidden;
		padding: 0 10px;
		box-sizing: border-box;
	}

	.footer {
		background:
			url('assets/second-page.svg') no-repeat bottom center,
			url('assets/bg.jpg') no-repeat center center;
		background-size: 120%, cover;
		height: 30vh;
		background-position: bottom center, center center;
	}

	.header-text {
		position: relative;
		margin-top: 4vh;
		padding-top: 2rem;
		text-align: center;
	}

	.line1 {
		font-size: clamp(1rem, 5vw, 2rem);
		white-space: normal;
		margin-top: 2vh;
	}

	.names {
		gap: 1rem;
		font-size: clamp(0.8rem, 3vw, 1.5rem);
		text-align: center;
	}

	.separator {
		width: 2rem;
		height: 2rem;
	}

	.date {
		font-size: clamp(1.1rem, 3vw, 2.1rem);
		text-align: center;
		margin-top: 1vh;
	}
}

@media (max-width: 320px) {
	.main-page {
		background-size: contain;
	}

	.footer {
		background:
			url('assets/second-page.svg') no-repeat bottom center,
			url('assets/bg.jpg') no-repeat center center;
		background-size: 120%, cover;
		height: 30vh;
		background-position: bottom center, center center;
	}

	.header-text {
		margin-bottom: 1vh;
		text-align: center;
	}

	.header-text .line1 {
		font-size: clamp(0.6rem, 4vw, 1.8rem);
		letter-spacing: 0.05rem;
		max-width: 90%;
		margin-bottom: 2vh;
	}

	.names {
		font-size: clamp(0.7rem, 3vw, 1.2rem);
		gap: 0.5rem;
		text-align: center;
	}

	.separator {
		width: 1.5rem;
		height: 1.5rem;
	}

	.date {
		font-size: clamp(0.8rem, 4vw, 1.2rem);
		text-align: center;
		margin-top: 1vh;
	}
}