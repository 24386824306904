

.fun-facts-section {
    padding: 50px 20px; 
    text-align: center; 
    background-color: #fcf5e9; 
}

.fun-facts-title {
    font-size: 2.5rem; 
    color: #050c52;; 
    margin-bottom: 30px; 
    font-family: 'Comodo Stamp', sans-serif; 
    text-transform: uppercase; 
    letter-spacing: 2px; 
}

.fun-facts-container {
    display: flex;
    flex-wrap: wrap; 
    gap: 20px; 
    justify-content: center; 
    align-items: center; 
    max-width: 1200px; 
    margin: 0 auto; 
}

.card {
    width: 220px; 
    height: 180px; 
    perspective: 1000px; 
}

.card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d; 
    transition: transform 0.6s ease; 
}

.card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; 
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid #e6e4df; 
    border-radius: 10px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    font-family: 'Comodo Stamp', sans-serif; 
    font-size: 1.2rem; 
    padding: 15px;
    box-sizing: border-box;
}

.card-front {
    background-color: #fff; 
    color: #050c52;; 
}

.card-back {
    background-color: #192d40; 
    color: #fff;; 
    transform: rotateY(180deg); 
}

.card:hover .card-inner {
    transform: rotateY(180deg); 
}