.scroll-to-top-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .scroll-to-top-btn img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .scroll-to-top-btn:hover {
    background-color: #f0f0f0;
  }
  