.event-location {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.section-title {
    font-size: 2rem;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
    line-height: 1.5;
}

.event-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
}

.event-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 calc(50% - 20px); 
    max-width: calc(50% - 20px);
    background: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    transition: all 0.3s ease; 
}

.event-map {
    width: 100%;
    height: 40vh;
    border: none;
    border-radius: 8px;
}

.event-details {
    text-align: center;
    color: #000;;
}

.event-details h3 {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.event-details p {
    font-size: 1rem;
    color: #333;
    margin: 5px 0;
}

.event-item:last-child {
    max-width: calc(50% - 20px); 
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.event-item:last-child .event-details h3 {
    font-size: 1.8rem;
    color: #333;
    text-transform: uppercase;
}

.event-item:last-child .event-details p {
    font-size: 1rem;
    color: #555;
}

.livestream-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #050c52;
    border: none;
    border-radius: 8px;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.livestream-button:hover {
    background-color: #353a73;
    transform: translateY(-2px);
}

.disabled{
    background-color: dimgrey;
    color: linen;
    opacity: 1;
    pointer-events: none;
  }

.livestream-button:active {
    background-color: #2736c9;
    transform: translateY(0);
}

@media (max-width: 768px) {
    .event-container {
        flex-direction: column;
        gap: 20px;
    }

    .event-item {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .event-item:last-child {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .livestream-button {
        width: 100%;
        max-width: 300px;
    }
}

@media (max-width: 480px) {
    .event-container {
        gap: 15px;
    }

    .event-item {
        padding: 10px;
    }

    .livestream-button {
        font-size: 0.8rem;
        padding: 8px 10px;
    }
}