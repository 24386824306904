.countdown {
    display: flex; 
    justify-content: center;
    align-items: center;
    gap: 10vw;
    font-size: clamp(1rem, 5vw, 2rem);
}

.countdown-item {
    display: flex;
    color: white;
    flex-direction: column; 
    align-items: center; 
}
